@import "@/assets/styles/global/lib.scss";













.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #f5f5fa;
  text-align: center;
  padding: size(200) size(15);
  z-index: 9;

  &.error-state {
    background: #ffcaca;
  }
  h2 {
    padding-right: 0;
    margin-bottom: size(12);
  }
  p {
    max-width: initial;
  }
  .spinner {
    margin: size(20) auto size(60);
    position: relative;
    border-top: size(4) solid var(--on-boarding-btn-bg);
    border-right: size(4) solid var(--on-boarding-btn-bg);
    border-bottom: size(4) solid var(--on-boarding-btn-bg);
    border-left: size(4) solid transparent;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
    font-size: 0;
    &, &:after {
      border-radius: 50%;
      width: size(80);
      height: size(80);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.ants-loader {
  background: var(--rz-ants-color-primary-bg);
  font-family: var(--rz-ants-font);

  &.loading-wrapper {
    .spinner {
      border-top: size(4) solid var(--rz-ants-primary-button-bg);
      border-right: size(4) solid var(--rz-ants-primary-button-bg);
      border-bottom: size(4) solid var(--rz-ants-primary-button-bg);
    }
  }

  h2, p {
    color: var(--rs-white-color);
  }
}
