
@keyframes jump-up {
  50% {
    transform: translateY(-2px) translateX(-3px);
  }
  75% {
    transform: translateY(-1px);
  }
}

@keyframes jump-up-shadow {
  50% {
    transform: translateY(-1px) translateX(-1px);
    box-shadow: 3px 3px 5px 0px #fdeca91f;
  }
  75% {
    transform: translateY(-1px);
    box-shadow: 3px 3px 5px 0px #fdeca91f;
  }
}


@keyframes drop-menu {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    // top: size(50);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes drop-menu-rev {
  0% {
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    // top: size(50);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes opacity-fill {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes grow {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes grow-rev {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-20px);
    max-height: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
    max-height: 0;
  }
}