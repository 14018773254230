.btn {
  display: inline-block;
  border-radius: size(4);
  font-size: size(12);
  padding: var(--btn-pad-top) var(--btn-pad-left);
  border: 1px solid;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  
  color: var(--regular-text-inv);
  &:hover {
    background: var(--regular-text-inv);
    color: var(--regular-text);
    border-color: var(--regular-text-inv);

    animation: jump-up-shadow 350ms ease-out;
  }

  &:disabled {
    opacity: 0.7;
  }

  &.focus {
    color: var(--hero-text);
    background: var(--master-highlight);
    border-color: var(--master-highlight);
    outline: none;

    &:hover {
      background: var(--hero-text);
      color: var(--regular-text-inv);
      border-color: var(--hero-text);
    }  
  }

  &.secondary {
    color: var(--secondary-text);
    border-color: var(--secondary-text);
    text-decoration: underline;

    &:hover {
      background: transparent;
      color: var(--hero-text);
      // border-color: var(--secondary-text);
    }  
  }

  &.on-bright-bg{
    color: var(--regular-text);
    &:hover {
      background: var(--master-bg);
      color: var(--regular-text-inv);
      border-color: var(--master-bg);
  
      animation: jump-up-shadow 350ms ease-out;
    }
  }

  &.size-tiny {
    padding: var(--btn-tiny-pad-top) var(--btn-tiny-pad-left);
  }

  &.no-border {
    border: none;
  }

  &.rounded {
    border-radius: size(24);

    color: var(--hero-text);
    background: transparent;
    border-color: var(--hero-text);

    &:hover {
      background: var(--hero-text);
      color: var(--regular-text-inv);
      border-color: var(--hero-text);
    }  
  }

  @media screen and (max-width: $breakpoint-md) {
    &.smaller-on-mobile {
      padding: size(7) size(10);
    }
  }

  &.icon-only {
    border: none;
  }
}

.console-btn {
  display: inline-block;
  border-radius: size(6);
  padding: size(13);
  text-align: center;
  border: none;
  cursor: pointer;
  background-color: var(--rz-link-color);
  color: var(--regular-text-inv);
  font-weight: bold;
  font-size: size(12);
  line-height: size(17);
  width: size(140);
  transition: all .3s;
  &.disabled-btn {
    cursor: not-allowed;
  }
}

.console-btn-ants {
  background: #D2FD49;
  padding: size(12);
  margin: 0 0 size(12);
  color: var(--rz-ants-color-text2);
  border-radius: size(4);
  border: size(1) solid #D2FD49;


  &:hover {
    background: transparent;
    color: var(--rs-white-color);
    border: size(1) solid #D2FD49;
    transition: none !important;
    padding: size(12);
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .overlay-menu {
    .svg-icon {
      &.regular-inv {
        .default-stroke {
          stroke: var(--secondary-text);
        }
      }
    }
  }
}

.svg-icon {
  display: inline-block;
  height: 100%;
  fill: none;
  stroke: none;
  transition: 240ms ease-in;
  cursor: pointer;

  .default-fill {
    fill: var(--master-highlight);
  }

  .default-stroke {
    stroke: var(--master-highlight);
  }

  &.regular-inv {
    .default-fill{
      fill: var(--regular-text-inv);
    }
  
    .default-stroke {
      stroke: var(--regular-text-inv);
    }
  }

  &.color-secondary {
    .default-fill{
      fill: var(--secondary-text);
    }
  
    .default-stroke {
      stroke: var(--secondary-text);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  // &:hover {
  //   .default-fill {
  //     fill: var(--master-bg);
  //   }
  
  //   .default-stroke {
  //     stroke: var(--master-bg);
  //   }
  // }
}

.split-cols {
  display: flex;

  > .fixed-col {
    flex-basis: var(--senior-menu-width);
  }

  > .flex-col {
    margin-left: var(--senior-menu-width);
    flex: 1;
    overflow: auto;
  }
}

.variable-box {
  font-family: var(--code-font);
  font-size: var(--schema-variable-font-size);
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;

  border-radius: size(4);
  color: var(--hero-text);
  border: 1px solid;

  line-height: var(--schema-variable-line-height);
  padding: size(2) size(4);
  @media screen and (min-width: $breakpoint-md) {
    padding: size(1.92) size(2.4);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(2.8) size(5.6);
  }

  &.json-path-box{
    background-color: var(--hero-text-variant-2);
    border-color:transparent;
    border-left: size(4) solid var(--hero-text);
    color: var(--regular-text);
    border-radius: 0;
    text-transform: none;
    word-wrap: break-word;
  }
}

.json-path-variable {
  font-family: var(--code-font);
  font-size: var(--schema-variable-font-size);
  line-height: var(--schema-variable-line-height);
  text-align: center;
  color: var(--hero-text);
}


.page-tabs {
  
  &.white-bg {
    background: var(--regular-text-inv);
    padding-left: size(12);
    
    a {
      padding: size(25) size(6);
      padding-bottom: size(25 - 5);
    }
  }

  ul {
    display: flex;
    font-size: size(14);
    border-bottom: size(1) solid var(--gray-line);

    max-width: calc(100vw - (2 * var(--docs-page-pad-left)));
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    li {
      flex: 0 0 auto;
      padding-right: size(20);

      &:last-child {
        padding-right: 0;
      }

      a {
        display: block;
        padding: size(17) size(6);
        border-bottom: size(5) solid transparent;
        padding-bottom: size(17 - 5);
        white-space: nowrap;
        color: var(--secondary-text);



        &:hover {
          color: var(--hero-text) !important;
          border-color: var(--hero-text);
        }
      }

      &.active {
        a {
          color: var(--hero-text) !important;
          border-color: var(--master-highlight);
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    ul {
      font-size: size(18);
      li {
        padding-right: size(24);
      }
    }
  }
}


.title-2, h2 {
  font-size: var(--title-2-font-size);
  line-height: var(--title-2-line-height);
  font-weight: bold;
  color: var(--hero-text);
  padding-right: var(--docs-page-pad-secondary-right);
}


.hide-on-mobile {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: inherit;
  }
}

.show-on-mobile {
  display: inherit;

  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}

.hide-on-desktop {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: inherit;
  }
}

.show-on-desktop {
  display: inherit;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.temp-page-container {
  padding:
    var(--docs-page-pad-top)
    0
    var(--docs-page-pad-bottom)
    var(--docs-page-pad-left);

  h1 {
    font-size: var(--title-font-size);
    line-height: var(--title-line-height);
    letter-spacing: var(--title-letter-space);
    color: var(--hero-text);
    padding-bottom: var(--title-pad-bottom);
  }

  h1, h2, h3 {
    margin: 2rem;
    font-family: var(--hero-font);
    font-weight: bold;    
  }

  p, span.p {
    padding: 2rem;
  }

  .box {
    margin: 2rem;
    > div {
      background: var(--box-color-1);
      margin-bottom: 1rem;
      padding: 2rem;
    }

    a {
      display: inline-block;
      margin-right: 1rem;
    }
  }
}

.link-secondary {
  text-decoration: underline;
}

.json-path {
  display: inline-block;
  word-wrap: break-word;
  font-family: var(--code-font);
  font-size: size(14);
  line-height: 1.2;


  background-color: var(--hero-text-variant-2);
  border-left: size(4) solid var(--hero-text);
  padding: size(4);
  margin-bottom: size(10);
  color: var(--regular-text);

  &.error-4xx {
    border-color: var(--status-color-critical);
    background-color: var(--status-color-critical-variant);

  }
}
.section-desc-area {
  .markdown {
    ol {
      list-style: decimal;
      padding-left: size(23);
    }
    span.p, ol >li {
      font-family: var(--regular-font);
      font-size: var(--section-para-font-size);
      line-height: var(--section-para-line-height);
      letter-spacing: 0.01em;
      max-width: var(--para-max-width)!important;
      color: var(--secondary-text);
      padding-right: var(--section-para-pad-right);
    }
    a {
      color: var(--hero-text);
      text-decoration: underline;
    }
    img {
      max-height: size(180);
      @media screen and (min-width: $breakpoint-md) {
        max-height: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-height: size(515);
      }
    }
  }
}

.error-page {
  --error-page-title-font-size: #{size(28)};
  --error-page-line-height: #{size(24)};
  --error-page-image-height: #{size(286)};
  --error-page-content-font-size: #{size(16)};
  --error-page-content-line-height: #{size(24)};

  @media screen and (min-width: $breakpoint-md) {
    --error-page-image-height: #{size(374)};
    --error-page-title-font-size: #{size(52)};
    --error-page-line-height: #{size(69)};
    --error-page-content-font-size: #{size(24)};
    --error-page-content-line-height: #{size(38)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --error-page-image-height: #{size(566)};
    --error-page-title-font-size: #{size(76)};
    --error-page-line-height: #{size(92)};
    --error-page-content-font-size: #{size(26)};
    --error-page-content-line-height: #{size(44)};
  }

  padding: var(--docs-page-pad-top) 0 0 var(--docs-page-pad-left);

  h1 {
    font-size: var(--error-page-title-font-size);
    font-family: var(--hero-font);
    font-weight: bold;
    line-height: var(--error-page-line-height);
    letter-spacing: var(--title-letter-space);
    color: var(--regular-text);
    margin: size(16) size(16) size(16) 0;
    padding-bottom: 0;
  }

  p, span.p {
    padding: 0 size(16) size(16) 0;
    font-size: var(--error-page-content-font-size);
    line-height: var(--error-page-content-line-height);
    font-family: var(--hero-font);
    letter-spacing: 0.02rem;
  }

  h1, h2, h3 {
    font-family: var(--hero-font);
    font-weight: bold;    
  }
}

.opacity-0 {
  opacity: 0;
}

.highlight {
  background-color: var(--highlight-color);
  padding: 0 0.4em;

  .highlight {
    padding: 0;
  }
}
