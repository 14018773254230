@import "@/assets/styles/global/lib.scss";















.page-request-state{
  .loading-msg {
    text-align: center;
    font-size: size(15);
    line-height: size(20);
    padding-bottom: size(10);
    color: var(--rs-black-color);
    img {
      margin-right: 10px;
    }
  }
  .loader {
    display: inline-block;
    width: size(15);
    height: size(15);
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
