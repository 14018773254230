* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: #{$base-size}px;
}

body {
  font-family: var(--regular-font);
  font-weight: 400;
  font-style: normal;

  // font-size: #{$base-size}px;
  color: var(--regular-text);
  // margin-top: var(--master-menu-height);
}

a {
  text-decoration: none;
  color: var(--regular-text);

  &:hover {
    color: var(--hero-text);
  }
}

b, em {
  font-weight: bold;
}

code {
  font-family: var(--code-font);
  font-size: size(14);
  white-space: pre-line;
}

p, span.p {
  max-width: var(--para-max-width);
}

// Following fix is for SSR hydration related, need to find a better way.
span.p {
  display: block;
}
