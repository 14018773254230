@import "@/assets/styles/global/lib.scss";































.error-msg {
  font-size: 12px;
  text-transform: uppercase;
  color: #9b9b9b;
  font-weight: normal;
  background-color: #fff;
  margin: 5px 0;
  text-align: center;
  border: 1px solid #e9e9e9;
  padding: 25px 0 25px;
}
